
import { defineComponent, PropType } from 'vue';
import { Result } from '@/tasoCli/shell';

export default defineComponent({
  name: 'CmdResult',
  props: {
    result: {
      type: Object as PropType<Result>
    }
  }
});
