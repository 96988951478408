
import { defineComponent, ref } from 'vue';
import { CmdData } from '@/tasoCli/shell';

type Resolve = (value: CmdData | PromiseLike<CmdData>) => void;

export default defineComponent({
  name: 'CmdLine',
  props: {
    cd: {
      type: String
    },
    cmd: {
      type: String
    }
  },
  setup() {
    const inputRef = ref<HTMLSpanElement>();
    const inputResolve = ref<Resolve>();

    const input = (): Promise<CmdData> => {
      return new Promise(resolve => {
        inputResolve.value = resolve;
      });
    };

    const submitCmd = {
      text: (): void => {
        if (!(inputRef.value && inputResolve.value)) {
          return;
        }
        inputResolve.value({
          type: 'text',
          data: inputRef.value.innerText
        } as CmdData);
        inputRef.value.innerText = '';
        inputResolve.value = undefined;
      },
      key: (e: KeyboardEvent) => {
        if (inputResolve.value) {
          inputResolve.value({
            type: 'key',
            data: e.key
          } as CmdData);
        }
      }
    };

    const keyInput = () => {
      if (inputResolve.value) {
        inputResolve.value({
          type: 'key',
          data: 'KEY_INPUT'
        } as CmdData);
      }
    };

    return {
      inputRef,
      inputResolve,
      input,
      submitCmd,
      keyInput
    };
  }
});
