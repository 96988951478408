export default {
  apiKey: 'AIzaSyCc7h9Dx4vy6OYdLXVzi1i5LILNKDqhMMk',
  authDomain: 'taso-cli.firebaseapp.com',
  databaseURL: 'https://taso-cli.firebaseio.com',
  projectId: 'taso-cli',
  storageBucket: 'taso-cli.appspot.com',
  messagingSenderId: '282029114227',
  appId: '1:282029114227:web:f3a2815088623fd615fb06',
  measurementId: 'G-E75MWW59RY'
};
